import { useContext } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { Logo } from './Logo';
import './ProgressHeader.css';
import { ProgressBar } from '../shared/ProgressBar';
import { ProgressNumber } from '../shared/ProgressNumber';

// render progress bar & progress number onto header
export const ProgressBarHeader = (props) => {
  const { progress, totalQuestions, completedQuestions, demoQuestionBool } = props;
  const { theme } = useContext(ConfigContext);

  return (
    <div className="header-progress">
      <Logo className="logo-header" fill={theme.primaryColor} />
      <div className="progress-wrapper">
        <ProgressNumber
          className="progress-number"
          totalQuestions={totalQuestions}
          completedQuestions={completedQuestions}
          demoQuestionBool={demoQuestionBool}
        />
        <ProgressBar className="progress-bar" progress={progress} />
      </div>
    </div>
  );
};
