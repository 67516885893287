import { Question, Screen, RedirectWithSearch, Loader } from '../shared';
import { UNINITIALIZED } from '../shared/constants';
import { useSurvey } from './useSurvey';
import { ProgressBarHeader } from '../header/ProgressBarHeader';

// render Demographic Questions Screen
export const DemographicQuestionsScreen = ({ surveyQuestionsPath }) => {
  const { question, answer,
    isSubmitting, isLoading,
    demographicProgress, demographicTotalQuestions, demographicCompletedQuestions } = useSurvey();

  if (!isLoading && question === null) {
    return <RedirectWithSearch to={surveyQuestionsPath} />;
  }
  return (
    <Screen loading={isLoading === UNINITIALIZED}>
      <Loader loading={isLoading}>
        {() => (
          <>
            <ProgressBarHeader
              progress={demographicProgress}
              totalQuestions={demographicTotalQuestions}
              completedQuestions={demographicCompletedQuestions}
              demoQuestionBool
            />
            <Question
              code={question.code}
              text={question.text}
              responses={question.responses}
              submitting={isSubmitting}
              onSubmit={answer}
            />
          </>
        )}
      </Loader>
    </Screen>
  );
};
