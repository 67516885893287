import { api } from '../api';

// Survey Question API Object
const SurveyQuestions = () => {
  // API call to TypeORM server to get user unanswered survey questions
  const getRandomUnanswered = (organizationId) =>
    api.get({
      path: '/surveyQuestions/unanswered/random',
      search: `?organizationId=${organizationId}`,
    });

  // API call to TypeORM server to save user survey answers
  const answer = ({ organizationId, questionCode, responseCode, value }) =>
    api.post({
      path: '/surveyQuestions/answers',
      body: {
        organizationId,
        questionCode,
        responseCode,
        value,
      },
    });

  // API call to TypeORM server to get current progress
  const getSurveyProgress = () =>
    api.get({
      path: '/surveyQuestions/progress',
    });

  // API call to see if daily question limit has been hit
  const getSurveyQuestionsDoneInLimit = () =>
    api.get({
      path: '/surveyQuestions/limit',
    });

  return {
    getRandomUnanswered,
    answer,
    getSurveyProgress,
    getSurveyQuestionsDoneInLimit,
  };
};

export const surveyQuestions = SurveyQuestions();
