import React from 'react';
import './ProgressNumber.css';

// render progress as # questions done / total questions (eg. 15/30)
// for ProgressBarHeader
export const ProgressNumber = (props) => {
  const { completedQuestions, totalQuestions, demoQuestionBool } = props;
  return (
    <div className="progress-number">
      {/* if we are in demographic questions then display 'setup:' */}
      {demoQuestionBool && 'Setup:'} {completedQuestions} /{' '}
      {totalQuestions}
    </div>
  );
};
