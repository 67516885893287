import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ConfigProvider } from './ConfigProvider';
import { Code, AuthProvider } from './auth';
import { DemographicQuestions } from './demographicQuestions';
import { SurveyQuestions } from './surveyQuestions';

function App() {
  return (
    <Router>
      <ConfigProvider>
        <AuthProvider>
          <main>
            <Switch>
              <Route path="/demographicQuestions" exact>
                <DemographicQuestions
                  authPath="/"
                  surveyQuestionsPath="/surveyQuestions"
                />
              </Route>
              <Route path="/surveyQuestions" exact>
                <SurveyQuestions
                  authPath="/"
                  demographicQuestionsPath="/demographicQuestions"
                />
              </Route>
              <Route path="/">
                <Code redirectTo="/demographicQuestions" />
              </Route>
            </Switch>
          </main>
        </AuthProvider>
      </ConfigProvider>
    </Router>
  );
}

export default App;
