import { useContext } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { Logo } from './Logo';
import './AuthHeader.css';

// render Header for auth screen
export const AuthHeader = () => {
  const { theme } = useContext(ConfigContext);

  return (
    <div className="header">
      <Logo className="logo-auth" fill={theme.primaryColor} />
    </div>
  );
};
