// holmetrics logo as component
export const Logo = ({ className, fill = '#000000' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112.82 40.25"
    fill={fill}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M48.72,9.62h0a5.34,5.34,0,0,0-7.44,0,5.34,5.34,0,0,0-7.44,0,4.8,4.8,0,0,0-1.13,3.68v6.26a1.06,1.06,0,0,0,2.11,0v-6A4.44,4.44,0,0,1,35.56,11a2.76,2.76,0,0,1,4,0,3.35,3.35,0,0,1,.63,1.64c0,.31,0,4.28,0,6.89a1.06,1.06,0,0,0,2.12,0c0-2.62,0-6.56,0-6.87A3.38,3.38,0,0,1,43,11a2.76,2.76,0,0,1,4,0,4.23,4.23,0,0,1,.74,2.49v6a1.06,1.06,0,0,0,2.11,0V13.3a4.8,4.8,0,0,0-1.13-3.68"
        />
        <path
          className="cls-1"
          d="M29,0A1,1,0,0,0,27.9,1.05V19.62a1.05,1.05,0,0,0,2.1,0V1.05A1,1,0,0,0,29,0"
        />
        <path
          className="cls-1"
          d="M63.7,13.92c0-3.06-1.73-6.15-5.59-6.15s-6.27,2.63-6.27,6.54a6.11,6.11,0,0,0,6.35,6.42,6.9,6.9,0,0,0,4.69-1.59A.88.88,0,0,0,63,18a.83.83,0,0,0-.59-.33.86.86,0,0,0-.66.19,5,5,0,0,1-3.19,1A4.18,4.18,0,0,1,54,14.6h9a.69.69,0,0,0,.69-.68m-9.62-1.1a3.82,3.82,0,0,1,4-3.17,3.42,3.42,0,0,1,3.47,3.17Z"
        />
        <path
          className="cls-1"
          d="M71,8.37H69.09V5.19a1.06,1.06,0,0,0-2.11,0V8.37H65.73a.95.95,0,0,0,0,1.89H67v9.36a1.06,1.06,0,0,0,2.11,0V10.26H71a.95.95,0,0,0,0-1.89"
        />
        <path
          className="cls-1"
          d="M97.69,17.92a.84.84,0,0,0-.6-.32.93.93,0,0,0-.66.2,4.67,4.67,0,0,1-3.15,1,4.26,4.26,0,0,1-4.39-4.53A4.27,4.27,0,0,1,93.2,9.68a5,5,0,0,1,2.7.81.93.93,0,0,0,1.25-.19,1,1,0,0,0,.19-.73A.91.91,0,0,0,97,8.94a6.67,6.67,0,0,0-3.72-1.17,6.3,6.3,0,0,0-6.52,6.52,6.27,6.27,0,0,0,6.37,6.44,6.39,6.39,0,0,0,4.56-1.62.89.89,0,0,0,0-1.19"
        />
        <path
          className="cls-1"
          d="M104,12.87c-1.16-.53-2.16-1-2.16-1.83,0-.69.48-1.49,1.83-1.49a3.61,3.61,0,0,1,2,.7.92.92,0,0,0,1.08-1.5,5.55,5.55,0,0,0-3.13-1c-2.27,0-3.91,1.44-3.91,3.42s1.71,2.71,3.23,3.44c1.33.63,2.48,1.18,2.48,2.27a2,2,0,0,1-2.27,2.05,4,4,0,0,1-2.61-.94.94.94,0,1,0-1.21,1.43,5.9,5.9,0,0,0,3.84,1.35c2.59,0,4.4-1.61,4.4-3.91s-2-3.27-3.55-4"
        />
        <path
          className="cls-1"
          d="M83.63,8.35A1.06,1.06,0,0,0,82.57,9.4V19.62a1.06,1.06,0,0,0,2.11,0V9.4a1,1,0,0,0-1.05-1.05"
        />
        <path
          className="cls-1"
          d="M83.58,7.66A1.34,1.34,0,0,0,85,6.31a1.37,1.37,0,0,0-1.44-1.39,1.35,1.35,0,0,0-1.35,1.39,1.33,1.33,0,0,0,1.35,1.35"
        />
        <path
          className="cls-1"
          d="M19.05,7.77a6.48,6.48,0,1,0,0,13,6.48,6.48,0,1,0,0-13m4.32,6.49A4.24,4.24,0,0,1,19,18.82a4.43,4.43,0,0,1-4.37-4.48A4.34,4.34,0,0,1,19,9.67a4.44,4.44,0,0,1,4.37,4.59"
        />
        <path
          className="cls-1"
          d="M16.22,7.18a1.35,1.35,0,0,0,1.45-1.34,1.38,1.38,0,0,0-1.45-1.4,1.36,1.36,0,0,0-1.35,1.4,1.34,1.34,0,0,0,1.35,1.34"
        />
        <path
          className="cls-1"
          d="M21.71,7.18a1.35,1.35,0,0,0,1.45-1.34,1.38,1.38,0,0,0-1.45-1.4,1.36,1.36,0,0,0-1.35,1.4,1.33,1.33,0,0,0,1.35,1.34"
        />
        <path
          className="cls-1"
          d="M9.2,9.75h0a5.44,5.44,0,0,0-4-1.64A5.62,5.62,0,0,0,2.11,9V1.05A1.06,1.06,0,0,0,0,1.05V19.62a1.06,1.06,0,0,0,2.11,0V13.35a3.1,3.1,0,1,1,6.19,0v6.27a1,1,0,0,0,2.1,0V13.68A5.2,5.2,0,0,0,9.2,9.75"
        />
        <path
          className="cls-1"
          d="M76.11,9.74V9.38a1.06,1.06,0,0,0-2.11,0V19.6a1.06,1.06,0,0,0,2.11,0V13.17a4.26,4.26,0,0,1,3.53-3,1,1,0,0,0,.81-.94,1,1,0,0,0-1.09-.95,6.47,6.47,0,0,0-3.25,1.5"
        />
        <path
          className="cls-1"
          d="M112.57,29.88h-.19l-.76,1.59-.77-1.59h-.19l-.26,2h.21l.21-1.64.7,1.44h.19l.7-1.43.2,1.63h.21Zm-3.22.19v1.82h.2V30.07h.64v-.19h-1.48v.19ZM105,37.49a2.18,2.18,0,1,1,2.15-2.14A2.13,2.13,0,0,1,105,37.49m2.15-3.57A2.48,2.48,0,0,0,105,32.67,2.64,2.64,0,0,0,105,38a2.5,2.5,0,0,0,2.18-1.25v1.12h.47V30.06h-.47ZM99,32.67a1.85,1.85,0,0,0-1.82,1.16v-1h-.47v5h.47V35a1.72,1.72,0,0,1,1.79-1.85,1.6,1.6,0,0,1,1.6,1.75v2.93H101v-3a2,2,0,0,0-2-2.2M92.6,37.49a2.12,2.12,0,0,1-2.15-2.2,2.16,2.16,0,1,1,4.31,0,2.12,2.12,0,0,1-2.16,2.2m0,.46a2.57,2.57,0,0,0,2.63-2.66A2.63,2.63,0,1,0,92.6,38m-5.67-.72L85.06,32.8h-.51l2.12,5-1,2.42h.5l3.17-7.45h-.51Zm-7.57-2.14a2,2,0,0,1,2.06-2,1.94,1.94,0,0,1,2,2Zm4.52.2a2.42,2.42,0,0,0-2.46-2.62,2.5,2.5,0,0,0-2.54,2.62,2.55,2.55,0,0,0,4.74,1.42l-.42-.2a2,2,0,0,1-1.77,1,2,2,0,0,1-2.07-2h4.52ZM73.22,37.36V34.11h2.29a1.67,1.67,0,0,1,1.71,1.69,1.55,1.55,0,0,1-1.61,1.56ZM76.8,32.2c0,.81-.44,1.48-1.22,1.48H73.22V30.87h2.13A1.28,1.28,0,0,1,76.8,32.2m-.52,1.66a1.82,1.82,0,0,0-.92-3.45H72.74v7.41h2.92a2,2,0,0,0,2-2,2,2,0,0,0-1.43-2M63.67,35.09a2,2,0,0,1,2-2,2,2,0,0,1,2,2Zm4.52.2a2.43,2.43,0,0,0-2.47-2.62,2.49,2.49,0,0,0-2.53,2.62,2.55,2.55,0,0,0,4.74,1.42l-.42-.2a2.06,2.06,0,0,1-1.77,1,2,2,0,0,1-2.07-2h4.52Zm-10.59-.2a2,2,0,0,1,2.06-2,1.94,1.94,0,0,1,2,2Zm4.52.2a2.42,2.42,0,0,0-2.46-2.62,2.5,2.5,0,0,0-2.54,2.62,2.55,2.55,0,0,0,4.74,1.42l-.42-.2a2,2,0,0,1-1.77,1,2,2,0,0,1-2.07-2h4.52ZM53.55,38A2.27,2.27,0,0,0,56,35.81c0-1.1-.87-1.67-2-1.94l-1-.25c-1.19-.29-1.33-.91-1.33-1.34a1.71,1.71,0,0,1,1.81-1.54,1.63,1.63,0,0,1,1.77,1.43h.48a2.07,2.07,0,0,0-2.25-1.89,2.17,2.17,0,0,0-2.29,2c0,.59.2,1.45,1.7,1.8l1,.23c.84.21,1.59.65,1.59,1.53a1.8,1.8,0,0,1-2,1.65A1.86,1.86,0,0,1,51.64,36h-.48a2.3,2.3,0,0,0,2.39,2"
        />
      </g>
    </g>
  </svg>
);
