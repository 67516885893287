import { useContext } from 'react';
import { RedirectWithSearch } from '../shared';
import { AuthContext } from './AuthProvider';

export const AuthGuard = ({ authPath, children }) => {
  const [isAuthorized] = useContext(AuthContext);

  if (isAuthorized === false) {
    return <RedirectWithSearch to={authPath} />;
  }

  return children;
};
