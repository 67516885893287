import { useState, useCallback, useContext, useEffect } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { UNINITIALIZED } from '../shared/constants';
import { surveyQuestions } from './surveyQuestions.service';

// handles answer submission, getting questions, getting user progress
// and checking to see if daily question limit has been reached for Survey.
export const useSurvey = () => {
  const { organizationId } = useContext(ConfigContext);

  const [question, setQuestion] = useState(UNINITIALIZED);
  const [surveyProgress, setSurveyProgress] = useState(0);
  const [surveyCompletedQuestions, setSurveyCompletedQuestions] = useState(0);
  const [surveyTotalQuestions, setSurveyTotalQuestions] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hitSurveyLimit, setHitSurveyLimit] = useState(false);

  // set intial state of widget, get random question and set current progress.
  useEffect(() => {
    surveyQuestions
      .getRandomUnanswered(organizationId)
      .then((randomQuestion) => {
        // TODO: handle errors
        setIsLoading(false);
        setQuestion(randomQuestion);
      });
    surveyQuestions.getSurveyProgress().then((result) => {
      setSurveyProgress(result.calculatedSurveyProgress);
      setSurveyCompletedQuestions(result.completedSurveyQuestions);
      setSurveyTotalQuestions(result.totalSurveyQuestions);
    });
    surveyQuestions.getSurveyQuestionsDoneInLimit().then((result) => {
      setHitSurveyLimit(result.hitLimit);
    });
  }, [organizationId]);

  // run when user submits an answer/skips.
  const answer = useCallback(
    ({ questionCode, responseCode, value }) => {
      setIsSubmitting(true);

      surveyQuestions
        .answer({
          organizationId,
          questionCode,
          responseCode,
          value,
        })
        .then(() => {
          // TODO: handle errors
          setIsSubmitting(false);
        })
        .then(() => {
          setIsLoading(true);

          return surveyQuestions.getRandomUnanswered(organizationId);
        })
        .then((randomQuestion) => {
          // TODO: handle errors
          setQuestion(randomQuestion);
          setIsLoading(false);
          // get new progress when answer is submitted.
          surveyQuestions.getSurveyProgress().then((result) => {
            setSurveyProgress(result.calculatedSurveyProgress);
            setSurveyCompletedQuestions(result.completedSurveyQuestions);
            setSurveyTotalQuestions(result.totalSurveyQuestions);
          });
          // check to see if daily question limit has been reached
          surveyQuestions.getSurveyQuestionsDoneInLimit().then((result) => {
            setHitSurveyLimit(result.hitLimit);
          });
        });
    },
    [organizationId]
  );

  return {
    question,
    isLoading: isLoading || question === UNINITIALIZED,
    isSubmitting,
    surveyProgress,
    surveyCompletedQuestions,
    surveyTotalQuestions,
    hitSurveyLimit,
    answer,
  };
};
