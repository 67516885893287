import { createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const colors = {
  primaryColor: '#000000',
  secondaryColor: '#14b687',
};

const defaultConfig = {
  theme: { ...colors },
};

export const ConfigContext = createContext(defaultConfig);

export const ConfigProvider = ({ children }) => {
  const { search } = useLocation();
  const config = useMemo(() => {
    const parsedSearch = new URLSearchParams(search);

    const organizationConfig = {
      theme: {
        ...defaultConfig.theme,
      },

      // TODO throw an error if org is not provided
      organizationId: parsedSearch.get('organizationId') || '1',
      userCode: parsedSearch.get('userCode'),
      pulse: parsedSearch.get('pulse'),
    };

    const parsedPrimaryColor = parsedSearch.get('primaryColor');
    const parsedSecondaryColor = parsedSearch.get('secondaryColor');

    // converts the string into a boolean which will be used to distinguish pulse and widget.
    if (organizationConfig.pulse) {
      const stringPulse = organizationConfig.pulse;
      organizationConfig.pulse = JSON.parse(stringPulse);
    }

    if (parsedPrimaryColor) {
      organizationConfig.theme.primaryColor = parsedPrimaryColor;
    }

    if (parsedSecondaryColor) {
      organizationConfig.theme.secondaryColor = parsedSecondaryColor;
    }

    return organizationConfig;
  }, [search]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
