import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { UNINITIALIZED } from '../shared/constants';
import { api } from '../api';
import { ConfigContext } from '../ConfigProvider';

export const AuthContext = createContext(UNINITIALIZED);

export const AuthProvider = ({ children }) => {
  const { organizationId, userCode } = useContext(ConfigContext);
  const [isAuthorized, setIsAuthorized] = useState(UNINITIALIZED);
  const cookies = new Cookies();

  const authState = useMemo(() => [isAuthorized, setIsAuthorized], [
    isAuthorized,
    setIsAuthorized,
  ]);

  useEffect(() => {
    if (!organizationId) return;

    if (userCode) {
      cookies.set('userCode', userCode, { path: '/', sameSite: 'none', secure: true });
    }

    api
      .get({ path: `/users/ping?organizationId=${organizationId}&userCode=${userCode}` })
      .then(({ authorized }) => setIsAuthorized(Boolean(authorized)))
      .catch(() => setIsAuthorized(false));
  }, [organizationId, userCode, setIsAuthorized]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};
