import React from 'react';
import './ProgressBar.css';

// render progress bar as a percentage left to do bar
// for ProgressBarHeader
export const ProgressBar = (props) => {
  const { progress } = props;
  return (
    <div className="progress-bar">
      <Filler percentage={progress} />
    </div>
  );
};

const Filler = ({ percentage }) => <div className="filler" style={{ width: `${percentage * 100}%` }} />;
