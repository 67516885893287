import { useCallback } from 'react';
import './TextInput.css';

export const TextInput = ({ value, onChange }) => {
  const handleChange = useCallback(
    ({ target }) => {
      onChange(target.value);
    },
    [onChange]
  );

  return <input className="textInput" value={value} onChange={handleChange} />;
};
