import { useMemo, useContext } from 'react';
import { ConfigContext } from '../ConfigProvider';
import './Button.css';

export const Button = ({
  className = '',
  styles,
  children,
  disabled,
  onClick,
}) => (
  <button
    className={`button ${className}`}
    type="submit"
    style={styles}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.Primary = ({ className, children, disabled, onClick }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useContext(ConfigContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const computedStyles = useMemo(
    () => ({
      backgroundColor: theme.secondaryColor,
    }),
    [theme.secondaryColor]
  );

  return (
    <Button
      className={className}
      disabled={disabled}
      styles={computedStyles}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

Button.Secondary = ({ className, children, disabled, onClick }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useContext(ConfigContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const computedStyles = useMemo(
    () => ({
      border: `1px solid ${theme.secondaryColor}`,
    }),
    [theme.secondaryColor]
  );

  return (
    <Button
      className={className}
      disabled={disabled}
      styles={computedStyles}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
