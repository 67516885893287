const API_HOST = process.env.REACT_APP_API_HOST ?? '';
const API_PREFIX = '/api';
const API_URL = `${API_HOST}${API_PREFIX}`.replace(
  `/${API_PREFIX}`,
  API_PREFIX
);

const API = () => {
  const get = ({ path, search }) =>
    fetch(`${API_URL}${path}${search ?? ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  const post = ({ path, body }) =>
    fetch(`${API_URL}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());

  return {
    get,
    post,
  };
};

export const api = API();
