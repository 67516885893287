import { Loader } from './Loader';
import './Screen.css';

export const Screen = ({ loading, children }) => (
  <div className="screen">
    <Loader loading={loading}>{() => children}</Loader>
  </div>
);

Screen.Title = ({ children }) => <h3 className="screen__title">{children}</h3>;
