import { api } from '../api';

// Demographic Questions API object
const DemographicQuestions = () => {
  // API call to TypeORM server to get unanswered demographic questions.
  const getAllUnanswered = (organizationId) =>
    api.get({
      path: '/demographicQuestions/unanswered',
      search: `?organizationId=${organizationId}`,
    });

  // API call to TypeORM server to save user's demographic answer.
  const answer = ({ organizationId, questionCode, responseCode, value }) =>
    api.post({
      path: '/demographicQuestions/answers',
      body: {
        organizationId,
        questionCode,
        responseCode,
        value,
      },
    });

  // API call to TypeORM server to get demographic progress.
  const getDemographicProgress = () =>
    api.get({
      path: '/demographicQuestions/progress' });

  return {
    getAllUnanswered,
    answer,
    getDemographicProgress
  };
};

export const demographicQuestions = DemographicQuestions();
