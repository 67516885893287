import { useState, useCallback, useContext } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { Button, TextInput, Screen, RedirectWithSearch } from '../shared';
import { UNINITIALIZED } from '../shared/constants';
import { AuthContext } from './AuthProvider';
import { AuthHeader } from '../header/AuthHeader';
import './Code.css';

// render screen to authenticate user if user is not already
export const Code = ({ redirectTo }) => {
  const { organizationId } = useContext(ConfigContext);
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);

  const [isBadUserCode, setisBadUserCode] = useState(false);
  const [userCode, setUserCode] = useState('');

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      fetch('/api/users/code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          organizationId,
          userCode,
        }),
      }).then(({ status }) => {
        if (status !== 201) {
          return setisBadUserCode(true);
        }

        return setIsAuthorized(true);
      });
    },
    [organizationId, userCode, setIsAuthorized]
  );

  if (isAuthorized === true) {
    return <RedirectWithSearch to={redirectTo} />;
  }

  return (
    <Screen loading={isAuthorized === UNINITIALIZED}>
      <div>       <AuthHeader /></div>
      <h3 style={{ color: 'white' }}>Please enter your code</h3>
      <form className="code__form" onSubmit={handleSubmit}>
        {isBadUserCode && <span style={{ color: 'white' }}>Bad code</span>}
        <TextInput value={userCode} onChange={setUserCode} />
        <Button.Primary className="code__submit" disabled={!userCode}>
          Submit
        </Button.Primary>
      </form>
    </Screen>
  );
};
