import { useCallback, useState, useContext } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { TextInput } from './TextInput';
import { Button } from './Button';
import './Question.css';

export const Question = ({ code, text, responses, submitting, onSubmit }) => {
  const { pulse } = useContext(ConfigContext);
  const [selectedResponseCode, setSelectedResponseCode] = useState(null);
  const [selectedResponseValue, setSelectedResponseValue] = useState(null);
  const [value, setValue] = useState('');

  const isDisabled = !(selectedResponseCode || value);

  const reset = useCallback(() => {
    setSelectedResponseCode(null);
    setValue('');
  }, []);

  const handleSubmit = useCallback(() => {
    reset();

    onSubmit({
      questionCode: code,
      responseCode: selectedResponseCode,
      responseValue: selectedResponseValue,
      value: selectedResponseValue,
    });
  }, [reset, onSubmit, code, selectedResponseCode, selectedResponseValue]);

  const handleSkip = useCallback(() => {
    reset();

    onSubmit({
      questionCode: code,
      responseCode: null,
      value: 'skip',
    });
  }, [onSubmit, code, reset]);

  const handleValueChange = useCallback((textInput) => {
    // if value is present, there are should not be response
    setSelectedResponseCode(() => null);
    setSelectedResponseValue(textInput);
    setValue(textInput);
  }, []);

  return (
    <div className={pulse ? 'question__pulse' : 'question'}>
      <div key={code} className="question__rollout">
        <span className={pulse ? 'question__span__pulse' : 'question__span'}>{text}</span>
        <div className={pulse ? 'question__responses__pulse' : 'question__responses'}>
          {responses.map(({ code: responseCode, text: responseText, value: responseValue }) => (
            <div key={responseCode} className={pulse ? 'question__response__pulse' : 'question__response'}>
              <input
                type="radio"
                id={responseCode}
                name={code}
                value={responseValue}
                onClick={() => {
                  setSelectedResponseCode(responseCode);
                  setSelectedResponseValue(responseValue);
                }}
              />
              <label htmlFor={responseCode}>{responseText}</label>
            </div>
          ))}
          {responses.length === 0 && (
            <TextInput value={value} onChange={handleValueChange} />
          )}
        </div>
      </div>
      <div className={pulse ? 'question__actions__pulse' : 'question__actions'}>
        {submitting ? (
          <div className="question__submitting">Submitting...</div>
        ) : (
          <>
            <Button.Secondary className="question__action" onClick={handleSkip}>
              Skip
            </Button.Secondary>
            <Button.Primary
              className="question__action"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Submit
            </Button.Primary>
          </>
        )}
      </div>
    </div>
  );
};
