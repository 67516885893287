import { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { ConfigContext } from '../ConfigProvider';
import './Loader.css';

const themeDependandStyles = (theme) => ({
  border: `4px solid ${theme.primaryColor}`,
  borderTopColor: `${theme.secondaryColor}`,
});

export const Loader = ({ loading, children }) => {
  const [shouldRenderLoader, setRender] = useState(loading);

  const { theme } = useContext(ConfigContext);
  const loaderStyle = useMemo(() => themeDependandStyles(theme), [theme]);
  const fadeStyle = useMemo(
    () => ({ animation: `${loading ? 'fadeIn' : 'fadeOut'} 1s` }),
    [loading]
  );

  // this effect react's on show updates
  useEffect(() => {
    if (loading) setRender(true);
  }, [loading]);

  const onAnimationEnd = useCallback(() => {
    if (!loading) setRender(false);
  }, [loading]);

  return (
    <>
      {shouldRenderLoader ? (
        <div style={fadeStyle} onAnimationEnd={onAnimationEnd}>
          <div className="loader" style={loaderStyle} />
        </div>
      ) : (
        children && children()
      )}
    </>
  );
};
