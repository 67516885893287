import { useEffect, useContext, useState } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { demographicQuestions } from '../demographicQuestions/demographicQuestions.service';
import { ProgressBarHeader } from '../header/ProgressBarHeader';
import { RedirectWithSearch, Screen, Loader, Question } from '../shared';
import { UNINITIALIZED } from '../shared/constants';
import { useSurvey } from './useSurvey';
import { Logo } from '../header/Logo';
import './EndScreen.css';

// render Survey Questions Screen
export const SurveyQuestionsScreen = ({ demographicQuestionsPath }) => {
  const { organizationId } = useContext(ConfigContext);
  const [
    redirectToDemographicQuestions,
    setRedirectToDemographicQuestions,
  ] = useState(false);

  useEffect(() => {
    demographicQuestions
      .getAllUnanswered(organizationId)
      .then(({ length: demographicQuestionsCount }) => {
        if (demographicQuestionsCount > 0) {
          setRedirectToDemographicQuestions(true);
        }
      });
  }, [organizationId]);

  const {
    question,
    answer,
    isSubmitting,
    isLoading,
    surveyProgress,
    surveyCompletedQuestions,
    surveyTotalQuestions,
    hitSurveyLimit,
  } = useSurvey();

  if (redirectToDemographicQuestions) {
    return <RedirectWithSearch to={demographicQuestionsPath} />;
  }

  if (hitSurveyLimit) {
    return (
      <Screen>
        <Logo className="logo" />
        <span className="words">Thanks for your submission(s). <br /> Have a good day! </span>
      </Screen>
    );
  }

  // display end screen if all questions completed
  if (!isLoading && question === null) {
    return (
      <Screen>
        <Logo className="logo" />
        <span className="words">Thanks for your participation!</span>
      </Screen>
    );
  }

  return (
    <Screen loading={isLoading === UNINITIALIZED}>
      <Loader loading={isLoading}>
        {() => (
          <>
            <ProgressBarHeader
              progress={surveyProgress}
              totalQuestions={surveyTotalQuestions}
              completedQuestions={surveyCompletedQuestions}
              demoQuestionBool={false}
            />
            <Question
              code={question.code}
              text={question.text}
              responses={question.responses}
              submitting={isSubmitting}
              onSubmit={answer}
            />
          </>
        )}
      </Loader>
    </Screen>
  );
};
