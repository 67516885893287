import { useState, useCallback, useContext, useEffect } from 'react';
import { ConfigContext } from '../ConfigProvider';
import { UNINITIALIZED } from '../shared/constants';
import { demographicQuestions } from './demographicQuestions.service';

// handles answer submission, getting questions and getting user progress for Demographicsd
export const useSurvey = () => {
  const { organizationId } = useContext(ConfigContext);

  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState(UNINITIALIZED);
  const [demographicProgress, setDemographicProgress] = useState(0);
  const [demographicCompletedQuestions, setDemographicCompletedQuestions] = useState(0);
  const [demographicTotalQuestions, setDemographicTotalQuestions] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoading = questions === UNINITIALIZED;

  // set initial state of questions and progress bar.
  useEffect(() => {
    demographicQuestions.getAllUnanswered(organizationId).then(setQuestions);
    demographicQuestions.getDemographicProgress()
      .then((result) => {
        setDemographicProgress(result.calculatedDemographicProgress);
        setDemographicCompletedQuestions(result.completedDemographicQuestions);
        setDemographicTotalQuestions(result.totalDemographicQuestions);
      });
  }, [organizationId, setQuestions]);

  // run when answer is submitted by user
  const answer = useCallback(
    ({ questionCode, responseCode, value }) => {
      setIsSubmitting(true);

      demographicQuestions
        .answer({
          organizationId,
          questionCode,
          responseCode,
          value,
        })
        .then(() => {
          // TODO: handle errors
          setIndex((i) => i + 1);
          setIsSubmitting(false);
          // get new progress when answer is submitted.
          demographicQuestions.getDemographicProgress().then((result) => {
            setDemographicProgress(result.calculatedDemographicProgress);
            setDemographicCompletedQuestions(result.completedDemographicQuestions);
            setDemographicTotalQuestions(result.totalDemographicQuestions);
          });
        });
    },
    [organizationId]
  );

  return {
    question: isLoading ? null : questions[index] ?? null,
    isLoading,
    demographicProgress,
    demographicCompletedQuestions,
    demographicTotalQuestions,
    isSubmitting,
    answer,
  };
};
